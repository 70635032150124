import Vue from 'vue'
import Vuex from 'vuex'
import applyForm from './modules/applyForm'
import CVForm from './modules/CVForm'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    applyForm,
    CVForm
  },
  strict: debug,
})

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import moment from "moment"

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  process.env.VUE_APP_FAIR_DAY.split(',').includes(moment().format("YYYY-MM-DD")) ?
    {
      path: '/',
      name: 'Online',
      component: () => import(/* webpackChunkName: "online" */ '../views/Online.vue')
    } : {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
  {
    path: '/exhibitors',
    name: 'Expositor',
    component: () => import(/* webpackChunkName: "exhibitors" */ '../views/Exhibitors.vue')
  },
  {
    path: '/welcome',
    name: 'La Feria',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue')
  },
  {
    path: '/contact',
    name: 'Contacto',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Contact.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/History.vue'),
    children: [
      {
        path: ':id',
      }
    ]
  },
  {
    path: '/applicants',
    name: 'Applicants',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Applicants.vue'),
    children: [
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "welcome" */ '../views/ApplyForm.vue'),
      }
    ]
  },
  {
    path: '/talks',
    name: 'Charlas',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Talks.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue';
import Vuetify, { VDatePicker } from 'vuetify/lib'
import VueI18n from 'vue-i18n';

Vue.use(Vuetify, { components: { VDatePicker } });
Vue.use(VueI18n)

const messages = {
    es: {
        $vuetify: {
            datePicker: {
                itemsSelected: '{0} Seleccionados',
                nextMonthAriaLabel: 'Mes siguiente',
                nextYearAriaLabel: 'Año siguiente',
                prevMonthAriaLabel: 'Mes anterior',
                prevYearAriaLabel: 'Año anterior'
            },
        },
    }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'es', // set locale
    messages, // set locale messages
})

export default new Vuetify({
    lang: {
        current: 'es',
        t: (key, ...params) => i18n.t(key, params),
    }
});

<template>
  <div class="online-video text-center">
    <youtube
      :video-id="videoId"
      allowfullscreen
      :player-vars="{ autoplay: 1, rel: 0, controls: 0 }"
      player-height="650px"
      player-width="100%"
      ref="live"
    ></youtube>
  </div>
</template>

<script>
import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";

Vue.use(VueYouTubeEmbed);

export default {
  props: {
    videoId: String,
  },
};
</script>

<style lang="sass">
.online-video
  height: auto
  *
    height: 200px
    width: 100%

@media (min-width: 700px)
  .online-video
    height: auto
    *
      height: 300px
      width: 100%

@media (min-width: 992px)
  .online-video
    height: 500px
    *
      height: 500px
      width: 100%
</style>

<template>
    <div class="uc-navbar_mobile d-block d-lg-none">
      <div class="uc-navbar_mobile-bar navbar-brand">
        <div class="uc-navbar_mobile-logo navbar-light">
          <div class="h1 text-font--serif text-color--blue">Feria del Trabajo</div>
        </div>
        <a
          href="javascript:void(0);"
          class="uc-navbar_mobile-button"
          data-collapse="collapseMobileNav"
        >
          <span class="uc-icon"></span>
          Menú
        </a>
      </div>
      <div
        class="uc-navbar_mobile-content"
        data-toggle="collapseMobileNav"
        data-open="false"
        :style="`height: ${height};`"
      >
        <div class="uc-navbar_mobile-list">
          <NavBarMobileItem
            v-for="(item, key) in menu"
            :key="key"
            :childrens="item.childrens"
            :title="item.title"
            :url="item.url"
          />
        </div>
      </div>
    </div>
</template>

<script>
import NavBarMobileItem from "./NavBarMobileItem.vue";
import menu from "@/assets/json/Header/NavBarMobile.json";

export default {
  components: {
    NavBarMobileItem
  },
  data() {
    return {
      height: 0,
      menu
    };
  }
};
</script>

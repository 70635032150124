<template >
  <div v-if="childrens.length" class="collapse-nav-mobile" v-on:click="collapseClick">
    <a
      href="javascript:void(0);"
      data-collapse="menu-collapse-1"
      class="list-item uc-collapse_heading"
    >
      {{ title }}
      <i class="uc-icon">keyboard_arrow_down</i>
    </a>
    <div data-toggle="menu-collapse-1">
      <div class="uc-navbar_mobile-list">
        <Item
          v-for="(item, key) in childrens"
          :key="`children-${key}`"
          :childrens="item.childrens"
          :title="item.title"
          :url="item.url"
          style="margin-left: 20px"
        />
      </div>
    </div>
  </div>
  <a v-else :href="url" class="list-item">{{ title }}</a>
</template>

<script>
import NavBarMobileItem from "./NavBarMobileItem";

export default {
  name: "Item",
  components: {
    Item: NavBarMobileItem
  },
  props: {
    childrens: Array,
    title: String,
    url: String,
  },
  methods: {
    collapseClick() {
      this.$parent.$data.height = "auto";
    }
  }
};
</script>

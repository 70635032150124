<template>
  <header class="uc-header">
    <div id="uc-global-topbar"></div>
    <nav class="uc-navbar" :style="false ? 'padding: 0 !important;' : ''">
      <Carousel v-if="showCarousel" />
      <Video v-if="showVideo" :video-id="videoId" />
      <!-- Menú para versión Escritorio -->
      <NavBar class="d-none d-lg-block" :showVideo="false" :menu="menu" />
      <!-- Menú para versión Móvil -->
      <NavBarMobile :showVideo="showVideo" />
    </nav>
  </header>
</template>

<script>
import Carousel from "./Header/Carousel.vue";
import NavBar from "./Header/NavBar.vue";
import NavBarMobile from "./Header/NavBarMobile.vue";
import Video from "./Header/Video.vue";
import axios from "axios";
import menu from "@/assets/json/Header/NavBar.json";
import moment from "moment";

export default {
  components: {
    Carousel,
    NavBar,
    NavBarMobile,
    Video,
  },
  data() {
    return {
      menu,
      showCarousel: !process.env.VUE_APP_FAIR_DAY.split(",").includes(
        moment().format("YYYY-MM-DD")
      ),
      showVideo: process.env.VUE_APP_FAIR_DAY.split(",").includes(
        moment().format("YYYY-MM-DD")
      ),
      showTimeline: false,
      timer: null,
      videoId: "Vp9OzR15Nuw",
      lastTransmition: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeMount() {
    // if (this.showTimeline && this.showVideo) {
    //   this.fetchTimeline();
    //   this.timer = setInterval(this.fetchTimeline, 60000);
    //   // this.timer = setInterval(this.updateTimeline, 1000);
    // }
  },
  methods: {
    updateTimeline() {
      const playlist = this.menu.reverse();
      const nowTimestamp = parseInt(moment().local().format("X"));
      const filteredPlaylist = playlist.filter((item) => {
        if (typeof item["childrens"] == "undefined") {
          item["childrens"] = [];
        }
        return parseInt(item.timestamp) > nowTimestamp;
      });

      if (filteredPlaylist.length == 0) {
        return;
      }

      if (playlist[filteredPlaylist.length]) {
        filteredPlaylist.push(playlist[filteredPlaylist.length]);
      }

      this.menu = filteredPlaylist.reverse();
      this.videoId = filteredPlaylist[0].id;
    },
    fetchTimeline() {
      axios
        .get(`${process.env.VUE_APP_REDES_ENDPOINT}/playlist`)
        .then((response) => {
          // this.menu = [];
          const playlist = Object.values(response.data);
          this.lastTransmition = playlist[0];
          const nowTimestamp = parseInt(moment().local().format("X"));
          const filteredPlaylist = playlist.filter((item) => {
            if (typeof item["childrens"] == "undefined") {
              item["childrens"] = [];
            }
            return parseInt(item.timestamp) > nowTimestamp;
          });

          // this.videoId = this.lastTransmition.id;
          if (filteredPlaylist.length == 0) {
            // this.menu.push(this.lastTransmition);
            return;
          }

          if (response.data[filteredPlaylist.length]) {
            filteredPlaylist.push(response.data[filteredPlaylist.length]);
          }

          // this.menu = filteredPlaylist.reverse();
          this.videoId = filteredPlaylist[0].id;
        });
    },
  },
};
</script>

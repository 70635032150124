<template>
  <div :class="showVideo ? 'timeline-div-navbar' : ''" ref="forward">
    <ul class="uc-navbar_nav">
      <li
        v-for="(item, key) in menu"
        :key="key"
        :class="`nav-item orange-block ${showVideo ? 'timeline-nav-item' : ''}`"
        @mouseover="mouseOver(item)"
      >
        <router-link :to="item.url" class="uc-btn btn-inline">
          <div v-if="key == 0 && showVideo" style="width: 100%">
            <h4>Ahora <span class="material-icons"> campaign </span></h4>
            {{ item.title }}
          </div>

          <div v-else-if="showVideo" class="row">
            <div class="col-md-12 mb-8">{{ setTime(item.timestamp) }}</div>
            <div class="col-md-12">{{ item.title }}</div>
          </div>

          <div v-else>{{ item.title }}</div>
          <div
            v-if="item.childrens.length"
            :data-dropdown="`menu${item.id}`"
            :class="`uc-dropdown_list ${show}`"
            @mouseleave="show = ''"
          >
            <div class="white-triangle"></div>
            <div
              v-for="subitem in item.childrens"
              :key="`menu-${item.id}-${subitem.id}`"
              class="uc-dropdown_list-item"
            >
              <router-link
                :to="`${item.url}${subitem.url}`"
                class="uc-btn btn-inline d-flex justify-content-center"
                >{{ subitem.title }}</router-link
              >
            </div>
          </div>
        </router-link>
        <i
          v-if="item.childrens.length"
          class="uc-icon color-black dropbtn"
          :data-dtarget="`menu${item.id}`"
          :ref="`menu${item.id}`"
          >arrow_drop_down</i
        >
        <div
          v-if="key == 0 && showVideo"
          class="slider-button backward"
          @click="scrollLeft()"
        >
          &lt;
        </div>
      </li>
      <div
        v-if="showVideo"
        class="slider-button forward"
        @click="scrollRight()"
      >
        &gt;
      </div>
    </ul>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    menu: Array,
    showVideo: Boolean,
  },
  data() {
    return {
      scroll: false,
      show: "",
      slider: null,
    };
  },
  methods: {
    scrollRight() {
      this.$refs.forward.scrollTo(this.$refs.forward.scrollLeft + 300, 0);
    },
    scrollLeft() {
      this.$refs.forward.scrollTo(this.$refs.forward.scrollLeft - 300, 0);
    },
    setTime(timestamp) {
      return moment.unix(timestamp).local().format("HH:mm");
    },
    mouseOver(item) {
      if (
        item.childrens &&
        typeof this.$refs[`menu${item.id}`] != "undefined"
      ) {
        this.show = "show";
      }
    },
  },
};
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,700&display=swap')
@font-face
  font-family: "Azo Sans Lt"
  src: url("/assets/fonts/06fcebf2389f170c4e0f9e5286b1c5f6.eot")

  src: url("/assets/fonts/06fcebf2389f170c4e0f9e5286b1c5f6.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/06fcebf2389f170c4e0f9e5286b1c5f6.woff2") format("woff2"), url("/assets/fonts/06fcebf2389f170c4e0f9e5286b1c5f6.woff") format("woff"), url("/assets/fonts/06fcebf2389f170c4e0f9e5286b1c5f6.ttf") format("truetype"), url("/assets/fonts/06fcebf2389f170c4e0f9e5286b1c5f6.svg#Azo Sans Lt") format("svg")



.slider-button:hover
  background-color: rgba(0,0,0,.1) !important

.slider-button
  cursor: pointer
  height: 100px
  padding-top: 40px
  position: absolute
  right: 0
  text-align: center
  width: 30px

.slider-button.backward
  color: #fff
  top: 0

.slider-button.forward
  background-color: #fff

.timeline-div-navbar
  overflow-x: auto
  height: auto
  ul
    margin: 0 0 !important
    height: 100px !important
    .orange-block:first-child
      height: 100px !important
      max-width: 300px
      position: absolute
      z-index: 1
      a
        font-size: 12pt
    li
      height: 100px !important
    li:nth-child(2)
      margin-left: 300px
    a:focus
      outline: 0px solid rgba(1,118,222,.7)

.timeline-nav-item
  min-width: 300px
  text-transform: uppercase
  .uc-btn.btn-inline
    border-bottom: 0 !important
    height: 48px
    width: 100%
    .uc-card.card-type--date
      height: 100px
      width: 100%
  a
    font-family: Azo Sans Lt !important
    font-size: 11pt
    white-space: pre-wrap

ul .orange-block:first-child
  background-color: #fd9827
  height: 48px
  margin-bottom: 0px
  a
    color: #fff !important
    height: 48px

li.nav-item > a
  color: #36363c !important
  font-family: 'Lato', sans-serif
  font-weight: bolder

.uc-navbar_nav
  height: 0
  position: initial !important

.uc-dropdown_list
  left: -50px

.uc-dropdown_list-item > td > a
  color: #000 !important
  width: 100%

.dropbtn
  cursor: pointer

.white-triangle
  left: 62px !important
</style>

import App from './App.vue'
import Vue from 'vue'
import VueGtag from "vue-gtag";
import VueCrontab from 'vue-crontab'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
Vue.use(VueCrontab)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
}, router);

new Vue({
  router,
  render: h => h(App),
  vuetify,
  store
}).$mount('#app')

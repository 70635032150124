<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";

export default {
  name: "App",
  metaInfo: {
    title: "Feria Del Trabajo Online - Redes Derecho UC",
    meta: [
      {
        name: "robots",
        content: "index, follow",
      },
      {
        name: "description",
        content:
          "La Feria del Trabajo Derecho UC es la mayor Feria legal que se realiza en el país y con el paso de los años se ha consolidado como un verdadero puente entre los mejores empleadores, alumnos y abogados del país.",
      },
    ],
  },
  components: {
    Footer,
    Header,
  },
  mounted() {
    document.body.id = "site-body";
    require("@digitaluc/uc-kitdigital/dist/js/uc-kitdigital");
  },
};
</script>

<style lang="sass">
@import "~@digitaluc/uc-kitdigital/dist/css/uc-kitdigital.css"

.container
  width: 100% !important
  padding-top: 0 !important
  padding-bottom: 0 !important
  padding-right: 16px !important
  padding-left: 16px !important
  margin-right: auto !important
  margin-left: auto !important

@media (min-width:576px)
  .container
    max-width: 540px !important

@media (min-width:768px)
  .container
    max-width: 720px !important

@media (min-width:992px)
  .container
    max-width: 960px !important

@media (min-width:1200px)
  .container
    max-width: 1216px !important

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1
  padding-top: 0 !important
  padding-bottom: 0 !important
  padding-right: 16px !important
  padding-left: 16px !important

.row
  margin: inherit !important
  margin-right: -16px !important
  margin-left: -16px !important
p
  text-align: justify

a.router
  color: #FFF
  text-decoration: none

body
  font-family: 'Lato', sans-serif !important

.text-font--serif
  font-size: 1.5em
  font-family: 'Lato', sans-serif !important

@media (max-width: 812px)
  .text-font--serif
    font-size: 2em

@media (max-width: 400px)
  .text-font--serif
    font-size: 1.5em

.tooltip
  display: block !important
  z-index: 10000

  &-inner
    background: black
    color: white
    border-radius: 16px
    padding: 5px 10px 4px
    max-width: 500px

  &-arrow
    width: 0
    height: 0
    border-style: solid
    position: absolute
    margin: 5px
    border-color: black
    z-index: 1

  &[x-placement^="top"]
    margin-bottom: 5px

  &-arrow
    border-width: 5px 5px 0 5px
    border-left-color: transparent !important
    border-right-color: transparent !important
    border-bottom-color: transparent !important
    bottom: -5px
    left: calc(50% - 5px)
    margin-top: 0
    margin-bottom: 0

  &[x-placement^="bottom"]
    margin-top: 5px

  &-arrow
    border-width: 0 5px 5px 5px
    border-left-color: transparent !important
    border-right-color: transparent !important
    border-top-color: transparent !important
    top: -5px
    left: calc(50% - 5px)
    margin-top: 0
    margin-bottom: 0

  &[x-placement^="right"]
    margin-left: 5px

  &-arrow
    border-width: 5px 5px 5px 0
    border-left-color: transparent !important
    border-top-color: transparent !important
    border-bottom-color: transparent !important
    left: -5px
    top: calc(50% - 5px)
    margin-left: 0
    margin-right: 0

  &[x-placement^="left"]
    margin-right: 5px

  &-arrow
    border-width: 5px 0 5px 5px
    border-top-color: transparent !important
    border-right-color: transparent !important
    border-bottom-color: transparent !important
    right: -5px
    top: calc(50% - 5px)
    margin-left: 0
    margin-right: 0

  &.popover
    $color: #f9f9f9

    .popover-inner
      background: $color
      color: black
      padding: 24px
      border-radius: 5px
      box-shadow: 0 5px 30px rgba(black, .1)

    .popover-arrow
      border-color: $color

  &[aria-hidden='true']
    visibility: hidden
    opacity: 0
    transition: opacity .15s, visibility .15s

  &[aria-hidden='false']
    visibility: visible
    opacity: 1
    transition: opacity .15s

.uc-modal
  z-index: 1000
</style>

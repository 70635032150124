const requiredFields = {
  rut: "",
  name: "",
  birth: "",
  email: null,
  phone: null,
  university: "",
};

const fields = {
  anotherLanguage: null,
  areas: null,
  assistantships: null,
  birth: null,
  condition: null,
  computing: null,
  diplomas: null,
  email: null,
  experiences: null,
  englishLevel: null,
  masters: null,
  name: null,
  personalInterests: null,
  phds: null,
  phone: null,
  politicalActivities: null,
  region: null,
  ranking: null,
  rut: null,
  score: null,
  socialWork: null,
  university: null,
};

// initial state
const state = () => ({
  fields: fields,
  required: requiredFields,
  requiredTooltip: requiredFields,
  universitiesList: [],
  areasList: [],
  regionsList: [],
  exhibitors: []
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  resetForm(state) {
    state.fields = fields;
    state.required = requiredFields;
    state.requiredTooltip = requiredFields;
  },
  setField(state, { id, value }) {
    state.fields[id] = value
  },
  setFieldArray(state, element) {
    if (state.fields[element.field] == null) {
      state.fields[element.field] = {}
    }
    if (!(element.id in state.fields[element.field])) {
      state.fields[element.field][element.id] = []
    }
    deleteIfExists(state.fields[element.field][element.id], element.value.id);
    state.fields[element.field][element.id] = state.fields[element.field][element.id].filter(el => el);
    state.fields[element.field][element.id].push(element.value)
  },
  setList(state, { list, values }) {
    state[list] = values;
  },
  clearRequired(state, id) {
    state.required[id] = ""
    state.requiredTooltip[id] = ""
  },
  required(state, { required, requiredTooltip }) {
    state.required = required;
    state.requiredTooltip = requiredTooltip;
  },
  resetRequired(state) {
    state.required = requiredFields;
    state.requiredTooltip = requiredFields;
  },
  exhibitors(state, array) {
    state.exhibitors = array;
  }
}

const deleteIfExists = (element, value) => {
  for (var i in element) {
    if (Object.values(element[i]).find(el => el == value)) {
      delete element[i];
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

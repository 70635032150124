<template>
  <div>
    <a v-for="(network, key) in networks" :key="key" :href="network.url" target="_blank">
      <img :src="`${baseUrl}/social-icon-${network.name}.svg`" :alt="network.name" />
    </a>
  </div>
</template>

<script>
import networks from "../assets/json/Footer/Networks.json";

export default {
  data() {
    return {
      baseUrl: "https://kit-digital-uc-prod.s3.amazonaws.com/assets",
      networks,
      hover: false
    };
  }
};
</script>

<style>
.social {
  display: grid;
  position: fixed;
  right: -100px;
  top: 20%;
  width: 150px;
}

.social > a {
  background-color: #9aca33;
  margin-bottom: 1px;
  margin-top: 1px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 50px;
}

@media (max-width: 992px) {
  .social {
    display: none;
  }
}

@media (min-width: 992px) {
  .uc-footer_social {
    display: none !important;
  }

  .social > a:hover {
    position: relative;
    right: 50px;
    width: 100px;
  }
}
</style>

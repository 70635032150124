<template>
  <div
    class="uc-carousel"
    data-carousel="uc-primary-carousel"
    data-type="carousel"
    data-items="1"
    data-slideby="1"
    data-loop="true"
    data-nav="true"
    data-autoplay="true"
    data-controls="false"
    data-navcontainer="#carousel-nav"
    data-autoplaybutton="#carousel-autoplay"
    data-autoplay-text="true"
  >
    <div class="uc-primary-carousel">
      <div
        v-for="(image, key) in images"
        :key="key"
        class="primary-carousel_item uc-card card-bg--image card-radius--none card-border--none"
        :style="getImgUrl(image)"
      >
        <div class="primary-carousel_item-content" v-show="image.content">
          <div class="container mt-auto">
            <div class="row">
              <div class="col-lg-7">
                <a href="#" class="uc-tag">Tag UC</a>
                <div class="h1 color-white">Slide #{{ key + 1 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uc-carousel-nav primary-carousel-nav" v-show="false">
      <div class="container">
        <div class="carousel-nav-container">
          <ul class="carousel-nav-list" id="carousel-nav">
            <li
              v-for="(image, key) in images"
              v-bind:key="key"
              class="carousel-nav-item"
            >
              <div class="number">{{ key + 1 }}</div>
              <div class="indicator"></div>
            </li>
          </ul>
          <div class="autoplay-buttons" id="carousel-autoplay"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import images from "@/assets/json/carousel.json";

export default {
  data() {
    return {
      images,
    };
  },
  methods: {
    getImgUrl(image) {
      return `
        background-image: url('${process.env.VUE_APP_REDES_BASE_PATH}/${image.path}');
      `;
    },
  },
};
</script>

<style lang="sass">
.uc-carousel .uc-primary-carousel .primary-carousel_item
  height: 90px !important
  min-height: 90px !important

@media (min-width: 550px)
  .uc-carousel .uc-primary-carousel .primary-carousel_item
    height: 120px !important
    min-height: 180px !important

@media (min-width: 750px)
  .uc-carousel .uc-primary-carousel .primary-carousel_item
    height: 200px !important
    min-height: 250px !important

@media (min-width: 992px)
  .uc-carousel .uc-primary-carousel .primary-carousel_item
    min-height: 300px !important

@media (min-width: 1300px)
  .uc-carousel .uc-primary-carousel .primary-carousel_item
    min-height: 400px !important

@media (min-width: 1600px)
  .uc-carousel .uc-primary-carousel .primary-carousel_item
    min-height: 500px !important

@media (min-width: 992px)
  .uc-navbar
    padding-top: 0 !important
</style>

const requiredFields = {
  academicActivities: "",
  areas: "",
  condition: "",
  email: "",
  firstPreference: "",
  languages: "",
  lawExperience: "",
  name: "",
  otherLanguage: "",
  phone: "",
  politicalActivities: "",
  ppa: "",
  rut: "",
  socialWork: "",
  sportActivities: "",
  university: "",
};

const fields = {
  rut: null,
  name: null,
  email: null,
  phone: null,
  university: "",
  condition: "",
  ppa: null,
  lawExperience: "emptyValue",
  areas: [],
  languages: [],
  otherLanguage: "emptyValue",
  academicActivities: "emptyValue",
  sportActivities: "emptyValue",
  socialWork: "emptyValue",
  politicalActivities: "emptyValue",
  firstPreference: null,
  secondPreference: null,
  thirdPreference: null,
  fourthPreference: null,
  fifthPreference: null
};

// initial state
const state = () => ({
  fields: fields,
  required: requiredFields,
  requiredTooltip: requiredFields,
  universitiesList: [],
  areasList: [],
  exhibitors: []
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  resetForm(state) {
    state.fields = fields;
    state.required = requiredFields;
    state.requiredTooltip = requiredFields;
  },
  setField(state, { id, value }) {
    state.fields[id] = value
  },
  clearRequired(state, id) {
    state.required[id] = ""
    state.requiredTooltip[id] = ""
  },
  required(state, { required, requiredTooltip }) {
    state.required = required;
    state.requiredTooltip = requiredTooltip;
  },
  resetRequired(state) {
    state.required = requiredFields;
    state.requiredTooltip = requiredFields;
  },
  universitiesList(state, array) {
    state.universitiesList = array;
  },
  areasList(state, array) {
    state.areasList = array;
  },
  exhibitors(state, array) {
    state.exhibitors = array;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
